import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WidgetMain } from './widget-main';

const widgetHostElement = document.getElementById('brevilleplus-ai-widget');

const root = ReactDOM.createRoot(
  widgetHostElement || document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <div className='brevilleplus-ai-root'>
      {widgetHostElement ? <WidgetMain /> : <App type={'app'} />}
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
