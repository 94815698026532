import React from "react";
import { useTrail, a } from '@react-spring/web'

export const Trail: React.FC<any> = (props) => {
    const {open, children} = props;
    const items = React.Children.toArray(children)
    const trail = useTrail(items.length, {
      config: { mass: 8, tension: 1000, friction: 100 },
      opacity: open ? 1 : 0,
      x: open ? 0 : 20,
      from: { opacity: 0, x: 10},
    })
    return (
      <div>
        {trail.map(({ ...style }, index) => (
          <a.div key={index} style={style}>
            <a.div>{items[index]}</a.div>
          </a.div>
        ))}
      </div>
    )
}  