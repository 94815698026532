import './scss/entry.scss'
import BrevillePlusLogo from '../assets/logo.svg';
import CookingIllustration from '../assets/cooking-illustration.svg';
import React from 'react';
import { Trail } from '../components/Trial';

export default function Entry(props: {open: boolean}){
    return(
        <div className='brevilleplus-entry d-flex'>
            <div className='logo'>
                <Trail open={props.open}>
                    <CookingIllustration/>
                    <div className='logo-spacer'/>
                    <BrevillePlusLogo/>
                </Trail>
            </div>
        </div>
    )
}