import { ReactNode, createContext, useContext, useState } from "react";
import { AppType } from "../../App";

export type ScreenVariations = 'convertRecipe' | 'helpMeCook' | 'inspireMe' | 'tipsAndClasses';
const ScreenContext = createContext<any>({});

export function ScreenProvider(props: {
    children: ReactNode,
    appType: AppType
}){

    const [currentScreen, setCurrentScreen] = useState<ScreenVariations|null>(null);

    const contextData = {
        currentScreen,
        setCurrentScreen,
        appType: props.appType
    }

    return(
        <ScreenContext.Provider value={contextData}>
            {props.children}
        </ScreenContext.Provider>
    )

}

export function useScreenContext(){
    const context = useContext(ScreenContext)
    return context;
}

export default ScreenProvider;