import './style/index.scss';
import ScreenProvider from './library/context/screenview';
import { Main } from './main';
import Button from 'react-bootstrap/Button';
import CloseSvg from './assets/close.svg';

export type AppType = 'app' | 'widget';

export type AppProps = {
  handleCloseClick?: ()=>any;
  type: AppType
}

function App(props: AppProps) {

  return (
    <div className={props.type === 'app' ?'brevilleplus-app': 'brevilleplus-floating-widget'}>
      {props.type==='widget' && 
        <Button className='position-absolute widget-close-button' variant='dark' onClick={props?.handleCloseClick}>
          <CloseSvg />
        </Button>}
      <ScreenProvider appType={props.type}>
        <Main />
      </ScreenProvider>
    </div>
  );
}

export default App;
