import { useState } from 'react';
import App from './App';
import BrevillePlusLogo from './assets/logo.svg';
import ChatDot from './assets/rocketchat.svg';
import { Trail } from './components/Trial';

export function WidgetMain(){

    const [isChatMode, setIsChatMode] = useState(false)
    return(
        <>
            <Trail open={!isChatMode}>
                <div className="brevilleplus-floating-widget" onClick={()=>setIsChatMode(!isChatMode)}>
                    <div className='brevilleplus-floating-button'>
                        <div className='brevilleplus-logo'>
                            <BrevillePlusLogo/>
                        </div>
                        <div className='chat-dot'>
                            <ChatDot />
                        </div>
                    </div>
                </div>
            </Trail>
            { isChatMode &&
                <Trail open={isChatMode}>
                    <App type="widget" handleCloseClick={()=>setIsChatMode(false)}/>
                </Trail>
            }
        </>
    )
}