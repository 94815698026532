import { Trail } from "../components/Trial";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Video from '../assets/Video.svg';
import Microwave from '../assets/microwave.svg';
import MicrowaveSouvide from '../assets/microwave-souvide.svg';
import GuidanceTips from '../assets/guidance-tips.svg';
import BrevillePlusLogo from '../assets/logo.svg';
import './scss/choice.scss';
import { ReactNode } from "react";
import { ScreenVariations, useScreenContext } from "../library/context/screenview";

function ChoiceCard(
    props: {
        logo: ReactNode,
        title: ReactNode | string,
        screenName: ScreenVariations,
        disabled?: boolean
    }){

    const {setCurrentScreen} = useScreenContext();
    return(
        <Card
            body
            className={`mt-3 choice-card ${props.disabled ? 'disabled' : '' }`}
            onClick={()=>{
                if(props.disabled) return;
                setCurrentScreen(props.screenName)
            }}
        >
            <div className="choice-card-body">
                <div>{props.logo}</div>
                <div>{props.title}</div>
            </div>
        </Card>
    )
}

export function Choice(){

    const {appType} = useScreenContext();

    const isWidget = appType === 'widget';

    return(
        <div className={`screen-choice-block ${isWidget ? 'screen-choice-block-widget' : ''}`}>
            <Trail open={true}>
                    <Row>
                        <Col lg={isWidget ? 12 : 6} sm={12}>
                            <ChoiceCard screenName='convertRecipe' logo={<Microwave />} title="Convert Recipe"/>
                        </Col>
                        <Col lg={isWidget ? 12 : 6} sm={12}>
                            <ChoiceCard screenName='helpMeCook' logo={<MicrowaveSouvide />} title="Help me cook"/>
                        </Col>
                        <Col lg={isWidget ? 12 : 6} sm={12}>
                            <ChoiceCard screenName='inspireMe' logo={<GuidanceTips />} title="Inspire me"/>
                        </Col>
                        <Col lg={isWidget ? 12 : 6} sm={12}>
                            <ChoiceCard screenName='tipsAndClasses' logo={<Video />} title="Tips & Classes"/>
                        </Col>
                    </Row>
            </Trail>
        </div>
    )

}