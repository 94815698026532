import { ScreenVariations, useScreenContext } from "../../library/context/screenview"
import BrevillePlusLogo from '../../assets/logo.svg';
import Cook from '../../assets/cook.svg';
import User from '../../assets/user.svg';
import LeftArrow from '../../assets/left-arrow.svg';
import SquareUp from '../../assets/arrow-up-square-fill.svg';
import ArrowDownCircle from '../../assets/arrow-down-circle-fill.svg';
import Form from 'react-bootstrap/Form';
import '../scss/chat.scss';
import { initialMessage } from "./helper";
import { useChat, experimental_useAssistant as useAssistant, Message } from "ai/react";
import { FormEvent, useEffect, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Markdown from 'markdown-to-jsx'
import { Loader } from "../../components/Loader";

export type ChatProps = {
    screenName: ScreenVariations;
}

export type AssistantProps = {
    role: 'system' | 'user' | 'assistant' | 'function' | 'data' | 'tool';
    content: any;
    data?: any;
    triggerMessageSubmit?: any;
}

// const baseURL = 'http://localhost:3000'
const baseURL = 'https://ai-service-steel.vercel.app'

function Card(props: any) {
    const {title, image_id, difficulty, timing: activeTime, triggerMessageSubmit, cuisineRegion=[]} = props
    const parsedImageId = JSON.parse(image_id||'{}');
    const thumbnailImage = parsedImageId?.url || 'https://staging-app.breville.com/thumbnail/recipeAuthor/1600136397_chefsteps_300_red_376x500.png';
    console.log('thumbnailImage', props)
    return (
    <div className='chat-display-card' onClick={()=>triggerMessageSubmit(title)}>
        <img alt={title} src={thumbnailImage}/>
        <div className="chat-display-cardtitle"><strong>{title}</strong></div>
        <div>
            <span>Difficulty:</span> {difficulty}<br/>
            Active time: {activeTime} minutes<br/>
        </div>
    </div>)

}

function ChatMessageCard(props: AssistantProps) {
    const {role, content, data, triggerMessageSubmit} = props;

    // if(role === 'data') {
    //     return(
    //     <div className='chat-display-cardlist'>
    //         {data?.map((each: any, index: any)=><Card key={index} {...each} />)}
    //     </div>)
    // }

    const isData = role === 'data';
    return(
        <div className="chat-message-card">
            <div className="chat-role-logo me-4">{role === 'assistant' || role === 'data' ? <Cook /> : <User />}</div>
            {isData ? 
                <div className='chat-display-cardlist'>
                {data?.map((each: any, index: any)=><Card key={index} triggerMessageSubmit={triggerMessageSubmit} {...each} />)}
                </div> : 
                <div className="chat-message-content">
                    <Markdown>
                        {content}
                    </Markdown>
                </div>
            }
        </div>
    )
}

export function Chat(props: ChatProps){

    // @ts-ignore
    const firstMessage = initialMessage[props.screenName] || {};
    const [gotMessages, setGotMessages] = useState(false);
    const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);
    const [contentBehindFold, setContentBehindFold] = useState(false);

    const buttonRef = useRef(null);
    const formRef = useRef(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const threadIdFromStorage = sessionStorage.getItem(props.screenName)
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // @ts-ignore
    const { messages, input, handleInputChange, submitMessage, setInput, status, threadId } = useAssistant({
        api: `${baseURL}/api/assistant/chat?assistantType=csRecipe`,
        headers: {'Content-Type': 'text/event-stream'}
    });

    threadId && sessionStorage.setItem(props.screenName, threadId)

    const isLoading = status === 'in_progress';

    const { setCurrentScreen, currentScreen } = useScreenContext();
    
    const message: Message[] = firstMessage.content ? [firstMessage,...messages] : messages;
    const handleMessageSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submitMessage(e);
        setGotMessages(false);
        scrollToBottom();
        if(!currentScreen) setCurrentScreen('defaultChoice');
    };


    function triggerMessageSubmit(data: any) {
       
        setInput(data)

        setTimeout(()=>{
            // @ts-ignore
            if(buttonRef.current) buttonRef.current.click();
        }, 100)
    }

    useEffect(()=>{
        if (isAutoScrollEnabled && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    },[messages, isAutoScrollEnabled])

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
          container.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          if (container) {
            container.removeEventListener('scroll', handleScroll);
          }
        };
      }, []);

    function handleScroll() {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            const calc = Math.round(scrollHeight - scrollTop) - clientHeight;
            const isAtBottom = calc < 5;
            const hasContentBehindFold = calc > 5;

            setContentBehindFold(hasContentBehindFold);

            if(!isAtBottom) {
                setIsAutoScrollEnabled(false);
            }
            else{
                setIsAutoScrollEnabled(true);
            
            }
          }
    }

    function scrollToBottom() {
        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }
    }


    return (
        <div style={{height: '100%'}}>
            <div className="chat-wrapper">
                
                <div className="chat-header p-4">
                    <Button onClick={()=>{setCurrentScreen(null)}} variant="light" className="position-absolute chat-back-btn">
                        <LeftArrow/>
                    </Button>
                    <BrevillePlusLogo/>
                </div>
                <div ref={containerRef} className="chat-body" id="brevilleplus-chat-screen" onScrollCapture={handleScroll}>
                    {message.map(each=><ChatMessageCard key={each.id} {...each} triggerMessageSubmit={triggerMessageSubmit}/>)}
                    {isLoading && <Loader />}
                </div>
                <div className="chat-input-box-wrapper">
                    {contentBehindFold && 
                        <button 
                            className="btn-behind-thefold position-absolute" 
                            onClick={scrollToBottom}
                        >
                            <ArrowDownCircle/>
                        </button>}
                    <Form onSubmit={handleMessageSubmit} ref={formRef} className="d-flex">
                        <Form.Control
                            className="chat-input-box"
                            type="text"
                            value={input}
                            placeholder="Message"
                            onChange={handleInputChange}
                        />
                        <button className="submit-message-button" type='submit' ref={buttonRef}><SquareUp/></button>
                    <input type='submit' ref={buttonRef} style={{display: 'none'}}/>
                    </Form>
                </div>
            </div>
        </div>
    )

}