import {ScreenVariations} from "../../library/context/screenview"
import { ChatMessageCardProps } from "./Chat";

export type InitialMessage = {
    [key in ScreenVariations]: ChatMessageCardProps;
};

export const initialMessage: InitialMessage = {
    convertRecipe: {
        id: 'initial_message',
        role: 'assistant',
        content: 'I can help you convert recipes intended for built-in ovens to use on Breville ovens. You can do this either by sending me the recipe URL or mention what Breville ovens you have.'
    },
    helpMeCook: {
        id: 'initial_message',
        role: 'assistant',
        content: 'I can help you cook based on the ingredients you have on hand, a recipe you want to cook, or just time and temp to cook something. Where would you like to start?'
    },
    inspireMe: {
        id: 'initial_message',
        role: 'assistant',
        content: 'I can help you cook based on any cuisine you like, dietary preference, or any cooking technique you want to learn more about. You can also send me a recipe link, and ask me what you need help with to get started.'
    },
    tipsAndClasses: {
        id: 'initial_message',
        role: "assistant",
        content: `I’m here to help with any questions about your Breville/Sage appliances, incl. how to clean your oven, descaling your coffee machine, and more. To get started, what appliance do you have and how can I help?'`
    }
}