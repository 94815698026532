import React, { useEffect, useState } from 'react';
import './style/index.scss';
import Entry from './page/Entry';
import { Choice } from './page/Choice';
import { useScreenContext } from './library/context/screenview';
import { Chat } from './page/Chat/ChatV2';

export function Main() {
  const [isEntry, setEntry] = useState(true)
  const [hideEntry, setHideEntry] = useState(false)
  const {currentScreen} = useScreenContext();

  useEffect(()=>{
    setTimeout(()=>{
      setEntry(false)
    },1000)
    setTimeout(()=>{
      setHideEntry(true)
    },1500)
  },[])
  return (
      <div className="brevilleplus-wrapper">
        {!hideEntry && <Entry open={isEntry} />}
        {hideEntry && !currentScreen && <Choice/>}
        <Chat screenName={currentScreen}/>
      </div>
  );
}